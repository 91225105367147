<template>
  <LoginLayout>
    <EmployedComponent :employed="employed" @change-page="getEmployess"></EmployedComponent>
    <yandex-go-modal-component></yandex-go-modal-component>
    <div  class="modal fade landing-page" id="EmployedDeleteModal" tabindex="-1" aria-labelledby="employedDeleteModalLabel"
      aria-hidden="true">
      <div class=" modal-dialog  modal-dialog-centered ">
        <div class="modal-content " style="border-radius: 8px;">
          <div class="modal-body p-4">
            <div class="d-flex justify-content-between mb-3">
              <h5 class="modal-title" id="employedDeleteModalLabel">Удалить работника</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div >
              <div class=" d-flex flex-wrap justify=-content-between User-info-component">
                <div  class=" py-3 px-3">
                  <p v-if="employedDeleteItem" >Вы уверены, что хотите удалить этого <br>
                   <span class="text-danger"> {{ (employedDeleteItem.firstName + " " + employedDeleteItem.lastName) }}</span> работника?</p>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <div>
                  <button data-bs-toggle="modal" id="EmployedDeleteModalCloseBtn" data-bs-target="#EmployedDeleteModal"
                    class="default-btn secondary-btn ">Отмена</button>
                </div>
                <div>
                  <button type="submit" @click=" removeEmployed(employedDeleteItem.id)"  class="btn default-btn frame-btn-secondary">Удалить</button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <section class="landing-page mt-5 container p-0" style="background-color: #FFFFFF; border-radius: 7px;">
      <div class="d-flex justify-content-between px-4 py-3">
        <div class="d-flex flex-wrap"> 
          <button @click="employed = employe" data-bs-toggle="modal"
            data-bs-target="#EmployedModal" class="default-btn frame-btn-secondary mx-0 me-2">+ Добавить исполнителя</button>
            <div class="dropdown mx-2">
            <button class="btn btn-warning dropdown-toggle" type="button" id="YandexGO"
              style="background-color: #FFDD33; border-color: #FFDD33; box-shadow: none; border-radius: 7px;"
              data-bs-toggle="dropdown" aria-expanded="false">
              Из Yandex Go
            </button>
            <ul class="dropdown-menu " aria-labelledby="YandexGO">
              <li data-bs-toggle="modal" data-bs-target="#SyncYandexGoModal"><a class="dropdown-item ">
                <i class="bi bi-cloud-arrow-down-fill position-absolute" style="color: #FF5A49; font-size: 22px; margin-top: -5px"></i>
                <span class="ms-3"> &nbsp;&nbsp; Загрузить</span></a></li>
              <li data-bs-toggle="modal" @click="HistoryYandexGo()" data-bs-target="#HistoryYandexGo"><a class="dropdown-item" href="#">
                <i class="bi bi-clock-history position-absolute" style="color: #FF5A49; font-size: 20px; margin-top: -5px"></i>
                <span class="ms-3"> &nbsp;&nbsp; История</span></a></li>
            </ul>
          </div>
          <div><button class="default-btn secondary-btn d-flex mx-2 me-0" data-bs-toggle="modal" data-bs-target="#uploadModal"
            style="background-color: darkgreen; color: white; font-weight: 400;">
                <div class="d-flex align-items-center mt-1 mx-1">
                  <i class="bi bi-file-earmark-spreadsheet position-absolute" style="font-size: 20px; margin-top: 15px"></i>
                </div>
                <span class="ms-2">
                  &nbsp;&nbsp; Выгрузка
                </span>
              </button></div>
              <UploadFileModalComponent></UploadFileModalComponent>
        </div>
        <div class="d-flex">
          <div class="User-info-component d-flex">
            <input type="text" class="input-custom search-btn" v-model="keyword" style=" background-color:#F6F6F6 ;border: none;"
              placeholder="Поиск по ФИО, ПИНФЛ" @keydown.enter="SearchEmployess(keyword)">
              <div  class=" align-items-center" :class="keyword.length ? ' d-flex ' : ' d-none '"
              @click="SearchEmployess('')">
                <i class="bi bi-x-lg"  style="margin-left: -25px; color: #FF5A49; cursor: pointer; font-weight: bold;"
                ></i>
            </div>
            <div class="d-flex align-items-center  me-2">
              <button class="default-btn secondary-btn ms-1" @click="SearchEmployess(keyword)">
                <i class="bi bi-search"></i>
              </button>
            </div>
            <div><button class="default-btn secondary-btn d-flex mx-2">
                <div class="d-flex align-items-center mt-1 mx-1">
                  <img style="width: 20px;" src="../assets/images/filter.png">
                </div>
                Фильтр
              </button></div>
            
          </div>
        </div>
      </div>
      <div>
        <table class="table">
          <thead class="text-gray "
            style="border-top: 1px solid #F1F2F5; border-bottom: none; background-color: #FCFCFC;">
            <tr>
              <th scope="col" class="ps-4 fw-500 " style="border-bottom: none;">
                <div class="text-center form-check">
                  <input class="form-check-input" :checked="allSelectedItems" @click="allSelected()" type="checkbox">
                </div>
              </th>
              <th scope="col" style="border-bottom: none;"> Фио</th>
              <th scope="col" style="border-bottom: none;">Дата рож.</th>
              <th scope="col" style="border-bottom: none;">Пинфл</th>
              <th scope="col" style="border-bottom: none;">Активность</th>
              <th scope="col" style="border-bottom: none;">Статус</th>
              <th scope="col" style="border-bottom: none;">Комитент</th>
              <th scope="col" style="border-bottom: none;">Субкомитент </th>
              <th scope="col" class="pe-4" style="border-bottom: none;"></th>
            </tr>
          </thead>
          <tbody>
            <tr class=" text-gray" v-if="employees.data" v-for="item in employees.data" :key="item">
              <td class="ps-4 text-center  align-self-center ">
                <div class="form-check">
                  <input class="form-check-input" :checked="isSelected(item.id)" @click="addSelectedItems(item.id)" type="checkbox">
                </div>
              </td>
              <th class="col-3" scope="row">
                <div class="d-flex "> 
                  <div class="d-flex justify-content-center align-items-center">
                    <img class="me-2" src="../assets/images/account.png" width="37px">
                  </div>
                  <span class="mb-0 fw-normal d-flex align-items-center text-wrap text-uppercase" style="font-size: 17px;">{{ item.firstName  + ' ' + item.lastName 
                    + ' ' +( item.middleName ?? '')}}</span>
                </div>
              </th>
              <td>
                <div>
                  <span>{{ item.birthDate ? (new Date(item.birthDate)).toLocaleDateString() : 'Нет данних' }} </span>

                </div>
              </td>
              <td class=""><p class="mb-0" v-if="item.pinfl">{{ item.pinfl }}</p></td>
              
              <td v-if="item.workingStatus == 0"><span class="user-activity-active">Включено </span></td>
              <td v-else-if="item.workingStatus == 1"><span class="user-activity-deactive">Выключено </span></td>
              <td v-else-if="item.workingStatus == 2"><span class="user-activity-invited">Уволен </span></td>
              <td v-else><span class="user-activity-invited">Нет данних </span></td>
              
              <td v-if="item.status == 0"><span class="user-activity-active">Активен </span></td>
              <td v-else-if="item.status == 1"><span class="user-activity-deactive">Не активен </span></td>
              <td v-else-if="item.status == 2"><span class="user-activity-invited">Приглашен </span></td>
              <td v-else-if="item.status == 3"><span class="user-activity-notInvited">Не приглашен</span></td>
              <td v-else><span class="user-activity-notInvited">Нет данних</span></td>

              <td>{{ item.egovActivityDate ? ("до " + (new Date(item.egovActivityDate)).toLocaleDateString().replaceAll('/','.')) : "Нет данних" }}</td>
              <td>{{ item.egovActivityDate ? ("до " + (new Date(item.egovActivityDate)).toLocaleDateString().replaceAll('/','.')) : "Нет данних" }}</td>
              <!-- <td v-if="item.card"><span>{{ item.card.substring(0,4) }}</span>  <span>****</span> 
                &nbsp;<span>****</span> <span>{{ item.card.substring(12,16) }}</span>&nbsp; <i class="bi bi-copy" data-bs-toggle="tooltip" data-bs-title="Копировать" @click="copyToClipboard(item.card)" style="cursor: pointer; color: #e98e00;"></i></td>
                <td v-else class="text-center">Нет данних</td> -->
              <td class="pe-2">
                <div id="EmployedActions" class="dropdown">
                  <button class="btn btn-secondary dropdown-toggle" style="background-color: transparent; border: none; box-shadow: none;" type="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <img src="../assets/images/more.png">
                  </button>
                  <ul class="dropdown-menu dropdown-menu">
                    <li class="dropdown-item" data-bs-toggle="modal"
                    data-bs-target="#EmployedModal" @click="showUpdateEmployed(item)"><span><i class="bi bi-pencil-square" style="color: orange;"></i></span> Редактировать</li>
                    <li data-bs-toggle="modal"
                    data-bs-target="#EmployedDeleteModal" @click="employedDeleteItem = item" class="dropdown-item"><span><i class="bi bi-trash3-fill" style="color: red;"></i></span> Удалить</li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class=" d-flex justify-content-between p-4 pb-2">
        <div class="d-flex mb-3">
          <p class="mb-0 mt-1 me-2">Показать</p>
          <div>
            <select class="form-select" v-model="pageSize" aria-label="Default select example">
              <option value=10 selected>10</option>
              <option value=15>15</option>
              <option value=20>20</option>
            </select>
          </div>
          <div>
            <p class="mb-0 mt-1 mx-2">из {{ employees.total }}</p>
          </div>
        </div>

        <pagination-component :page-size="pageSize" :total-items="employees?.total" @change-page="getEmployess"></pagination-component>

      </div>
    </section>
  </LoginLayout>
</template>

<script>
import LoginLayout from '../layouts/LoginLayout.vue'
import EmployedComponent from '../components/EmployedComponent.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex';
import PaginationComponent from '../components/PaginationComponent.vue';
import YandexGoModalComponent from '../components/modals/YandexGoModalComponent.vue';
import UploadFileModalComponent from '../components/modals/UploadFileModalComponent.vue';
export default {
  name: 'HomeView',
  data() {
    return {
      keyword:'',
      pageSize: 10,
      allSelectedItems: false,
      selectedItems: [],
      currentPage: 1,
      employedDeleteItem: null,
      employed: {
        firstName: '',
          lastName: '',
          middleName: '',
          gender: 1,
          birthDate: '',
          pinfl: '',
          card: '',
          egovActivityDate: null,
          phone : ''
      },
      employe: {
        firstName: '',
          lastName: '',
          middleName: '',
          gender: 1,
          birthDate: '',
          pinfl: '',
          card: '',
          egovActivityDate: null,
          phone : ''
      }
      
    }
  },
  methods: {
    ...mapActions([
      'getAllEmployeds_api',
      'deleteEmployed_api',
      'getHistoryYandexGo_api'
    ]),
    ...mapGetters([
      'getAllEmployeds',
      'getCommittentInfo'
    ]),
    ...mapMutations([
      'setErrorMessage'
    ]),
    async getEmployess(page) {
      let params = {
        committentId: this.CommittentInfo?.id
      }
      params.limit = this.pageSize;
      if (page > 1) {
        params.skip = this.pageSize * (page-1);
      }   
      if (this.keyword.length > 3) {
        params.keyword = this.keyword; 
      }
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth'
      });
      await this.getAllEmployeds_api(params);
      const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
      const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));
      let query = {};
      if(this.keyword.length > 3) {
        query.search = this.keyword
      }
      query.limit = this.pageSize;
      query.page= page;
      this.$router.replace({query: query})
    },
    async SearchEmployess(text) {
      if (text.length != 0 && text.length < 4) {

        this.setErrorMessage({
          message: "Текст поиска должен быть ПИНФЛ или не менее 4 символов.",
          type: "danger"
        });
      }
      else if (text.length == 0 && !(this.$route.query.search ?? false)) {
        this.keyword = '';
        return;
      }
      else {
        this.keyword = text;
        await this.getEmployess(1);
      }
    },
    addSelectedItems(id) {
      if (this.selectedItems.includes(id)) {
        this.selectedItems.splice(this.selectedItems.indexOf(id), 1);
      }
      else {
        this.selectedItems.push(id);
      }
    },
    showUpdateEmployed(item) {
      this.employed = item;
    },
    allSelected() {
      if (this.allSelectedItems) {
        this.selectedItems = [];
        this.allSelectedItems = false;
      }
      else {
        this.employees.data.forEach(item => {
          this.selectedItems.push(item.id);
        })
        this.allSelectedItems = true;
      }
    },
    copyToClipboard(card) {
      navigator.clipboard.writeText(card);
      this.setErrorMessage('Копировать в буфер обмена.');
    },
    isSelected(id) {
      return this.selectedItems.includes(id);
    },
    async removeEmployed(id) {
      await this.deleteEmployed_api(id).then((response) => {
              this.setErrorMessage("Самозанятость успешно удалено")
              let modal = document.getElementById('EmployedDeleteModalCloseBtn');  
              if (response.status && response.status == 200) {
                modal.click();
              }
            });
      this.employedDeleteItem = null; 
      await this.getEmployess(this.currentPage);
    },
    HandlerPageSize(num) {
      if (num < 11)
        return 10;
      else if (num < 16) 
        return 15;
      else
        return 20;
    },
    async HistoryYandexGo() {
      let params = {
        committentInn: this.CommittentInfo?.inn
      }
      await this.getHistoryYandexGo_api(params);
    }
  },
  components: {
    LoginLayout,
    EmployedComponent,
    PaginationComponent,
    YandexGoModalComponent,
    UploadFileModalComponent
  },
  computed: {
    employees() {
      return this.getAllEmployeds();
    },
    CommittentInfo() {
      return this.getCommittentInfo();
    }
  },
  watch: {
    async 'CommittentInfo' (to,from) {
      if (!this.getAllEmployeds()?.data && to != "") {
        let page =  isNaN(this.$route.query.page) == false ? this.$route.query.page  : 1;
        await this.getEmployess(page)
        
      }
    },
    '$route.query.page'(to,from) {
      this.currentPage = isNaN(this.$route.query.page) == false ? this.$route.query.page  : 1;

    }
  },
  beforeMount() {
    this.pageSize = this.HandlerPageSize(this.$route.query.limit?? 0);
    this.keyword = this.$route.query.search?? ''
  }

}
</script>
