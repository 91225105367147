
import apiService from "@/services/apiService";
const state = {
    committentSettings: ''
}

const getters = {
    getCommittentSettings(store) {
        return store.committentSettings;
    }
}

const mutations = {
    setCommittentSettings(contex, payload) {
        contex.committentSettings = payload;
    }
}   

const actions = {
    async GetCommittentSettings_api(contex, id) {
        let options = {
            params: id,
            mutationFunc: 'setCommittentSettings',
            callFunction: apiService.Commitents.getCommittentSettings
        }
        return await contex.dispatch('requestHandler',options);
    },
    async CreateCommittentSettings_api(contex, params) {
        let options = {
            params: params,
            mutationFunc: 'setResponse',
            callFunction: apiService.Commitents.createCommittentSettings
        }
        return await contex.dispatch('requestHandler',options);
    },
    async UpdateCommittentSettings_api(contex, params) {
        let options = {
            params: params,
            mutationFunc: 'setResponse',
            callFunction: apiService.Commitents.updateCommittentSettings
        }
        return await contex.dispatch('requestHandler',options);
    }

}


export default {
    state,
    getters,
    mutations,
    actions
}