import axios from './axios.js'

const authService = {
    async getTokenWithFaktura(code) {
        let body = {
            code: code,
            redirectUri : window.location.href.split('?')[0]
        }
        let result = await axios.post("auth/token", body);
        return result;
    },
     Authorization() {
        return localStorage.getItem('token') ?? false;
    },
     SaveToken(token) {
        localStorage.setItem('token',token); 
    },
    RemoveToken() {
        localStorage.removeItem('token');
    }
}

export default authService;