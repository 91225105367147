<template>
    <div class="modal fade" id="SyncYandexGoModal" tabindex="-1" aria-labelledby="SyncYandexGoModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="max-width: 600px;">
            <div class="modal-content ">
                <div class="modal-body p-3">
                    <div class="d-flex justify-content-between">
                        <h5 class="modal-title" id="SyncYandexGoModalLabel">Импорт водителей из Yandex Go</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="p-2 mt-2">
                        <p class="mb-0">
                            Мы загрузим только тех
                            водителей, у которых корректно заполнен ПИНФЛ. Импорт не будет
                            добавлять дубликаты.
                        </p>
                    </div>
                </div>
                <div class=" d-flex flex-wrap justify-content-end User-info-component">
                    <div class=" col-12 py-3 px-3 d-flex justify-content-end align-items-end">
                        <div>
                            <button data-bs-toggle="modal" id="SyncYandexGoCloseBtn" data-bs-target="#SyncYandexGoModal"
                                class="default-btn secondary-btn ">Отмена</button>
                        </div>
                        <div>
                            <button type="submit" @click="sendRequest" 
                            class="btn default-btn frame-btn-secondary">Начать загрузку</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal  fade" id="HistoryYandexGo" aria-hidden="true" aria-labelledby="HistoryYandexGoLabel"
        tabindex="-1">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header" style="border-bottom: none;">
                    <h5 class="modal-title" id="HistoryYandexGoLabel">История Yandex Go</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <table class="table ">
                        <thead class="text-gray"
                            style="border-top: 1px solid rgb(241, 242, 245); border-bottom: none; background-color: rgb(252, 252, 252);">
                            <tr>
                                <th scope="col" style="border-bottom: none;">№</th>
                                <th scope="col" style="border-bottom: none;">Дата</th>
                                <th scope="col" style="border-bottom: none;">Синхронизированные водители</th>
                                <th scope="col" style="border-bottom: none;">Всего водителей</th>
                                <th scope="col" style="border-bottom: none;">Статус</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index ) in historyYandexGo?.data">
                                <th scope="row">{{ index + 1 }}</th>
                                <td>{{ (new Date(item.createdAt)).toLocaleDateString() }} {{ (new Date(item.createdAt)).toLocaleTimeString() }}</td>
                                <td>{{ item.syncedCount ?? 0 }}</td>
                                <td>{{ item.totalCount ?? 0 }}</td>
                                <td v-if="item.status == 0"><span class="user-activity-active">Загружено </span></td>
                                <td v-else-if="item.status == 1"><span class="user-activity-deactive">Не загружено</span></td>
                                <td v-else-if="item.status == 2"><span class="user-activity-invited">В процессе </span></td>
                            </tr>
                            <!-- <tr>
                                <th scope="row">2</th>
                                <td>12.02.2024 12:14:32</td>
                                <td>1000</td>
                                <td>1000</td>
                                <td><span class="user-activity-active">Загружено</span></td>

                            </tr>
                            <tr>
                                <th scope="row">3</th>
                                <td>12.02.2024 12:14:32</td>
                                <td>50</td>
                                <td>1000</td>
                                <td><span class="user-activity-deactive">Не загружено</span></td>

                            </tr> -->
                        </tbody>
                    </table>
                    <div class="d-flex justify-content-center align-items-center">
                        <PaginationComponent :page-size="pageSize" @change-page="changePage" :total-items="historyYandexGo?.total ?? 0">
                        </PaginationComponent>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import PaginationComponent from '../PaginationComponent.vue';

export default {
    data() {
        return {
            pageSize: 10
        }
    },
    components: {
        PaginationComponent
    },
    methods: {
        ...mapGetters([
            'getCommittentInfo',
            'getHistoryYandexGo'
        ]),
        ...mapMutations([
            'setErrorMessage'
        ]),
        ...mapActions([
            'getHistoryYandexGo_api',
            'startYandexGo_api'
        ]),
        async changePage(page) {
            let params = {
                committentInn: this.CommittentInfo?.inn
            }
            params.limit = this.pageSize;
            if (page > 1) {
                params.skip = this.pageSize * (page - 1);
            }
            await this.getHistoryYandexGo_api(params);
        },
        async sendRequest() {
           await this.startYandexGo_api(this.CommittentInfo?.inn).then((response) => {
                var closebtn = document.getElementById("SyncYandexGoCloseBtn");
            if (response.status && response.status == 200) {
                    this.setErrorMessage('Синхронизация началась успешно');
                    closebtn.click();
                }
                else if (response.status && response.status == 400) {
                    let message = {
                        message: "Синхронизация уже выполняется.",
                        type: 'danger'
                    }
                    this.setErrorMessage(message);
                    closebtn.click();
                }
           });
           let params = {
                committentInn: this.CommittentInfo?.inn
            }
            await this.getHistoryYandexGo_api(params);
           
        }
    },
    computed: {
        CommittentInfo() {
            return this.getCommittentInfo();
        },
        historyYandexGo() {
            return this.getHistoryYandexGo();
        }
    },
}
</script>