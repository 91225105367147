<template>
    <div class="toast-container position-fixed  start-50 translate-middle-x" style="z-index: 1155; top: 75px;">
        <div id="liveToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="alert  mb-0 d-flex justify-content-between" :class="(' alert-'+ message.type)">
                {{message.message}}
                <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
    methods: {
        ...mapGetters([
            'getErrorMessage',
            'getHasError'
        ]),
        ...mapMutations([
            'setErrorMessage'
        ])
    },
    computed: {
        errorMessage() {
            return this.getHasError();
        },
        message() {
            return this.getErrorMessage();
        }
    },
    watch: {
        'errorMessage'(to, from) {
            if (this.errorMessage != "") {
                const toastLiveExample = document.getElementById('liveToast')
                const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample)
                toastBootstrap.show()
                }
        }
    },
    mounted() {
            if (this.errorMessage != "") {
                    const toastLiveExample = document.getElementById('liveToast')
                    const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample)
                    toastBootstrap.show()
                }
    }
}
</script>