<template>
    <div id="mainfooter">
      <footer>
        <div class="container">
          <div class="row">
            <div class="Contact-footer">
              <div id="contact" class="d-flex align-items-center justify-content-center mb-3">
                <div> <img src="../../assets/images/call.png" alt="icon" class="img-phone-footer" /></div>
                <div>
                  <h3>+998 71 200 00 53</h3>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-center mb-3">
                <div> <img src="../../assets/images/call.png" alt="icon" class="img-phone-footer" /></div>
                <div>
                  <h3>+998 71 200 00 13</h3>
                </div>
              </div>
              <p>
                <i class="fa fa-map-marker text-dark"></i>
                Ташкент, Юнусабадский район, ул. Дехканадабад, 1</p>
              <p>© Faktura.uz 2020 г. Все права защищены</p>
  
  
              <div class="d-flex justify-content-center">
                <a class="mx-1" href="https://www.instagram.com/faktura.uz/?hl=ru">
                  <img src="../../assets/images/instagram.png" width="30">
                </a>
                <a class="mx-1" href="https://facebook.com/fakturauz">
                  <img src="../../assets/images/facebook.png" width="30">
                </a>
                <a class="mx-1" href="https://www.youtube.com/channel/UCJwAsKcNAoX083ByjSgkBRg">
                  <img src="../../assets/images/youtube.png" width="30">
                </a>
                <a class="mx-1" href="https://t.me/fakturauz">
                  <img src="../../assets/images/telegram.png" width="30">
              </a>
            </div>
  
          </div>
        </div>
      </div>
    </footer>
  </div></template>
  
  <script defer>
  export default {
    name: 'mainfooter'
  }
  </script>
  