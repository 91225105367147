const validation = {
    isPinfl(pinfl) {
        return !/^\d{14}$/.test(pinfl);
    },
    isName(name) {
        return !/[A-Za-z'`‘]+$/.test(name);
    },
    isCard(payload) {
        return !/^(\d{4}[- ]?){3}\d{4}$/.test(payload) && (payload != '');
    },
    isPhoneNumber(payload) {
        return !/^\+998\d{9}$/.test(payload);
    },

}


export default validation;