import { createStore } from 'vuex';
import registerStore from './registerStore';
import employedsStore from './employedsStore';
import committentStore from './committentStore';
import syncStore from './syncStore';
export default createStore({
  state: {
    inProcess: false,
    errorMessage: {
      message: '',
      type: 'success'
    },
    hasError: 0
  },
  getters: {
    getProcess(state) {
      return state.inProcess;
    },
    getErrorMessage(state) {
      return state.errorMessage;
    },
    getHasError(state) {
      return state.hasError
    }
  },
  mutations: {
    setIsProcess(state,boolen){
      state.inProcess += boolen ? 1 : -1;
    },
    setErrorMessage(state, message) {
      state.hasError += 1;
      state.errorMessage.message = message?.message ?? message;
      state.errorMessage.type = message?.type ?? 'success';
    }
  },
  actions: {
    async requestHandler(contex, payload) {
      contex.commit(payload.mutationFunc, '');
      try {
        contex.commit('setIsProcess', true)
        const response = await payload.callFunction(payload.params);

          if (response.status == 200) {
              if (response.data) {
                  let responseData = response.data;
                  contex.commit(payload.mutationFunc, responseData);
              }
          }
          else {
              contex.commit('setErrorMessage', response.data.Title);
              // router.push({name: 'notFound'});
          }
          return response;
      } 
      catch (e) {
          // contex.commit('setErrorMessage', e.response.data.Title);
          // if (e.response.status == 404) 
          return e.response;
      }
      finally {
        contex.commit('setIsProcess', false)
      }
    },
  },
  modules: {
    registerStore,
    employedsStore,
    committentStore,
    syncStore
  }
})
