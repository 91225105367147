import axios from './axios.js'

function objToQuery(obj) {
    let query = '';
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
        }
        else {
            query += propName + '=' + obj[propName] + '&'
        }
    }
    query =  query.slice(0, -1);
    return query;
}

const apiService = {
    Employeds: {
        async getSelfEmployeds(filter) {
            let query = objToQuery(filter);
            query = (query.length > 0) ? ('?' + query) : '';
            let response = await axios.get('/self-employeds'+ query);
            return response;
        },
        async getSelfEmployedsSearch(filter) {
            let query = objToQuery(filter);
            query = (query.length > 0) ? ('?' + query) : '';
            let response = await axios.get('/self-employeds/search'+ query);
            return response;
        },
        async getSelfEmployedById(id) {
            let response = await axios.get('/self-employeds/' + id);
            return response;
        },
        async createSelfEmployed(body) {
            let response = await axios.post('/self-employeds', body);
            return response;
        },
        async updateSelfEmployeds(employed) {
            let response =  await axios.put('/self-employeds/'+ employed.id, employed);
            return response;
        },
        async deleteSelfEmployeds(id) {
            let response = await axios.delete('/self-employeds/' + id);
            return response;
        },
        async getNameByPinfl(pinfl) {
            let response = await axios.get('/self-employeds/name/' + pinfl);
            return response;
        },
        async uploadExcel(body) {
            let response = await axios.post('/self-employeds/import', body);
            return response;
        }
    },
    Commitents: {
        async getCommitentInfo(){
            let response = await axios.get('/committents');
            return response;
        },
        async getCommittentSettings(id){
            let response = await axios.get("committent-settings/"+ id);
            return response;
        },
        async createCommittentSettings(params) {
            let response = await axios.post("committent-settings", params);
            return response;
        },
        async updateCommittentSettings(params) {
            let response = await axios.put("committent-settings/" + params.id, params);
            return response;
        }
    },
    Sync: {
        async startYandexGo(inn) {
            let response = await axios.get("/sync/start/" + inn);
            return response;
        },
        async historyYandexGo(params) {
            let query = objToQuery(params);
            query = (query.length > 0) ? ('?' + query) : '';
            let response = await axios.get('/sync/history'+ query);
            return response;
        }
    }
}

export default apiService;