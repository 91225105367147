<template>
<div class="modal fade" id="SettingsModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="SettingsModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="d-flex justify-content-between p-3">
        <h4 class="modal-title" id="SettingsModalLabel">Настройки</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="p-2">
            <div class="User-info-component mb-4">
                <label for="ClientId">Client ID</label>
                <input id="ClientId" class="input-custom" v-model="clienId" type="text">
            </div>
            <div class="User-info-component mb-4">
                <label for="ApiKey">API Key</label>
                <input id="ApiKey" class="input-custom" v-model="apiKey" type="text">
            </div>
            <div class="User-info-component">
                <label for="ParkId">Park ID</label>
                <input id="ParkId" class="input-custom" v-model="parkId" type="text">
            </div>
        </div>
      </div>
      <div class=" py-3 px-4 d-flex justify-content-end align-items-end">
            <div>
            <button id="SettingsModalClose" data-bs-toggle="modal" data-bs-target="#SettingsModal"
                class="default-btn secondary-btn ">Отмена</button>
            </div>
            <div>
            <button type="submit"  @click="CreateSettings()"  class="btn default-btn frame-btn-secondary">Сохранить</button>
            </div>
        </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
    data() {
        return {
            settingsId: 0,
            committentId: 0,
            clienId: '',
            apiKey: '',
            parkId: ''
        }
    },
    props: {
        id: {
            reqiured: true,
            type: Number
        }
    },
    methods: {
        ...mapGetters([
            'getCommittentSettings',
            'getCommittentInfo'
        ]),
        ...mapActions([
            'GetCommittentSettings_api',
            'CreateCommittentSettings_api',
            'UpdateCommittentSettings_api'
        ]),
        ...mapMutations([
            'setErrorMessage'
        ]),
        async CreateSettings() {
            let body = {
                committentId: this.committentId,
                setting1: this.clienId,
                setting2: this.apiKey,
                setting3: this.parkId
            }
            if (this.settings) {
                body.id = this.settingsId;
                await this.UpdateCommittentSettings_api(body).then((response) => {
                    let closebtn = document.getElementById('SettingsModalClose');
                    if (response.status && response.status == 200) {
                        this.setErrorMessage("Успешное обновление настройки комитента")
                        closebtn.click();
                    }
                })
            }
            else {

                await this.CreateCommittentSettings_api(body).then((response) => {
                    let closebtn = document.getElementById('SettingsModalClose');
                    if (response.status && response.status == 200) {
                        this.setErrorMessage("Успешное создание настройки комитента")
                        closebtn.click();
                    }
                })
                await this.GetCommittentSettings_api(this.committentId);
            }
        }
    },
    watch : {
        'settings' (to,from) {
            this.settingsId = this.settings?.id ?? 0;
            this.clienId = this.settings?.setting1 ?? '';
            this.apiKey =this.settings?.setting2 ?? '';
            this.parkId = this.settings?.setting3 ?? '';
        },
        'committentInfo' (to,form) {
            this.committentId = this.committentInfo?.id ?? 0;

        }
    },
    computed: {
        settings() {
            return this.getCommittentSettings();
        },
        committentInfo() {
            return this.getCommittentInfo();
        }
    }
}
</script>