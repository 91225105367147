<template>
        <nav v-if="pagination.totalPage > 1" aria-label="Page navigation example">
            <ul class="pagination justify-content-center mb-0">
                <li class="page-item " :class="pagination.hasPrevious ? '' : ' disabled'">
                    <button class="page-link" @click="redirect(1)">
                        <i class="bi bi-chevron-double-left fs-6"></i>
                    </button>
                </li>
                <li class="page-item " :class="pagination.hasPrevious ? '' : ' disabled'">
                    <button class="page-link" @click="redirect(pagination.currentPage - 1)"><i class="bi bi-chevron-left fs-6"></i></button>
                </li>

                <li class="page-item" v-if="pagination.currentPage > 1"> <button class="page-link"
                        @click="redirect(1)">1</button></li>
                <li class="page-item disabled" v-if="(pagination.currentPage > 3) && (pagination.currentPage -1 < pagination.totalPage)"> <button class="page-link">...</button>
                </li>
                <li class="page-item" v-if="(pagination.currentPage - 1 > 1)  && (pagination.currentPage -1 < pagination.totalPage)"> <button class="page-link"
                        @click="redirect(pagination.currentPage - 1)">{{ pagination.currentPage - 1 }}</button></li>
                <li class="page-item active" v-if="pagination.currentPage  && (pagination.currentPage - 1 < pagination.totalPage)"> <button class="page-link"
                        @click="redirect(pagination.currentPage)">{{ pagination.currentPage }}</button></li>
                <li class="page-item " v-if="pagination.currentPage + 1 < pagination.totalPage"> <button
                        class="page-link" @click="redirect(pagination.currentPage + 1)">{{ pagination.currentPage +
                        1}}</button></li>
                <li class="page-item disabled" v-if="pagination.currentPage + 2 < pagination.totalPage"> <button
                        class="page-link">...</button></li>
                <li class="page-item " v-if="pagination.currentPage < pagination.totalPage"> <button class="page-link"
                        @click="redirect(pagination.totalPage)">{{ pagination.totalPage }}</button></li>

                <li class="page-item" :class="pagination.hasNext ? '' : 'disabled'">
                    <button class="page-link" @click="redirect(pagination.currentPage + 1)"><i class="bi bi-chevron-right fs-6"></i></button>
                </li>
                <li class="page-item " :class="pagination.hasNext ? '' : ' disabled'">
                    <button class="page-link" @click="redirect(pagination.totalPage)">
                        <i class="bi bi-chevron-double-right fs-6"></i>
                    </button>
                </li>
            </ul>
        </nav>
</template>

<script>
export default {
    data() {
        return {
            currentPage: 1,  
            isPrevious: false,
            isNext: true,

        }

    },
    props: {
        pageSize: {
            required: true,
            type: Number
        },
            totalItems: {
            required: true,
            type: Number
        }
    },
    computed: {
        pagination() {
            return {
                totalPage: Math.ceil(this.totalItems/this.pageSize),
                currentPage: this.currentPage,
                hasPrevious: this.currentPage> 1,
                hasNext: Math.ceil(this.totalItems/this.pageSize) > this.currentPage
            }
        }
    },
    watch: {
        'pageSize' (to,from) {
            this.currentPage = 1;
            this.redirect(this.currentPage)
        },
        "$route.query.page" (to,from) {
            this.currentPage = isNaN(to) == false ? Number.parseInt(to) : 1;
        }
    },
    methods: {
        redirect(page) {
            this.currentPage = page;
            this.$emit("change-page", page);
        }
    },
    mounted() {
        console.log(this.$route.query.page);
        
        this.currentPage = isNaN(this.$route.query.page) == false ? Number.parseInt(this.$route.query.page) : 1;
    }
}
</script>