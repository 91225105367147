
import apiService from "@/services/apiService";
const state = {
    employeds: '',
    employedById: '',
    requestRespone: '',
    committentInfo: ''
}

const getters = {
    getAllEmployeds(store) {
        return store.employeds;
    },
    getEmployedById(store) {
        return store.employedById;
    },
    getResponse(store) {
        return store.requestRespone;
    },
    getCommittentInfo(store) {
        return store.committentInfo;
    }
}

const mutations = {
    setAllEmployeds(store, payload) {
        store.employeds = payload;
    },
    setEmployedById(store, payload) {
        store.employedById = payload;
    },
    setResponse(store, payload) {
        store.requestRespone = payload;
    },
    setCommittentInfo(store, payload) {
        store.committentInfo = payload;
    }
}   

const actions = {
    async getCommittentInfo_api(contex) {
        let options = {
            mutationFunc: 'setCommittentInfo',
            callFunction: apiService.Commitents.getCommitentInfo
        }
        return await contex.dispatch('requestHandler',options)
    },
    async getAllEmployeds_api(contex, query) {
        let options = {
            params: query,
            mutationFunc: 'setAllEmployeds',
            callFunction : query.keyword ? apiService.Employeds.getSelfEmployedsSearch : apiService.Employeds.getSelfEmployeds
        };
       return await contex.dispatch('requestHandler', options);
    },
    async getAllEmployedById_api(contex, query) {
        let options = {
            params: query,
            mutationFunc: 'setEmployedById',
            callFunction : apiService.Employeds.getSelfEmployedById
        };
        await contex.dispatch('requestHandler', options);
    },
    async createEmployed_api(contex, body) {
        let options = {
            params: body,
            mutationFunc: 'setResponse',
            callFunction : apiService.Employeds.createSelfEmployed
        };
       return await contex.dispatch('requestHandler', options);
    },
    async updateEmployed_api(contex, body) {
        let options = {
            params: body,
            mutationFunc: 'setResponse',
            callFunction : apiService.Employeds.updateSelfEmployeds
        };
       return await contex.dispatch('requestHandler', options);
    },
    async deleteEmployed_api(contex, id) {
        let options = {
            params: id,
            mutationFunc: 'setResponse',
            callFunction: apiService.Employeds.deleteSelfEmployeds
        }
        return await contex.dispatch('requestHandler',options);
    },
    async getNameEmployeeByPinf_api(contex, pinfl) {
        let options = {
            params: pinfl,
            mutationFunc: 'setResponse',
            callFunction: apiService.Employeds.getNameByPinfl
        }
        return await contex.dispatch('requestHandler', options);
    },
    async uploadEmployessInExcel_api(contex, body) {
        let options = {
            params: body,
            mutationFunc: 'setResponse',
            callFunction: apiService.Employeds.uploadExcel
        }
        return await contex.dispatch('requestHandler', options);
    }
}


export default {
    state,
    getters,
    mutations,
    actions
}