<template>
    <div>
  
      <div  class="modal fade"   id="uploadModal" tabindex="-1" aria-labelledby="uploadModalLabel" aria-hidden="true">
        <div class="modal-dialog  modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header" style="border-bottom: none;">
              <h5 class="modal-title">Загрузить из файла</h5>
              <button type="button" class="btn-close" data-bs-toggle="modal" data-bs-target="#uploadModal" @click="closeModal"></button>
            </div>
            <div class="modal-body">
            <p>Загрузите файл Excel формата .XLS, .XLSX <br><a href="/sample.xlsx" style="color: #FF5A49;"  download>Скачать шаблон файла</a></p>
              <div
                class="dropzone text-center"
                :class="{ dragover: isDragOver }"
                @click="triggerFileInput"
                @dragover.prevent="isDragOver = true"
                @dragleave="isDragOver = false"
                @drop="handleDrop"
              >
              <div class="col-12 text-center">
                {{ dropzoneText }}
              </div>
              </div>
              <input
                type="file"
                ref="fileInput"
                @change="handleFileChange"
                accept=".xlsx, .xls"
                style="display: none;"
              />
            </div>
            <div class="modal-footer" style="border-top: none;">
              <button type="button" class="default-btn secondary-btn" data-bs-toggle="modal" id="UploadCloseBtn" data-bs-target="#uploadModal" @click="closeModal">
                Отмена
              </button>
              <button type="button" class="btn default-btn frame-btn-secondary" @click="uploadFile">
                Загрузить   
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
import { mapActions, mapMutations } from 'vuex';

  export default {
    data() {
      return {
        showModal: false,
        isDragOver: false,
        dropzoneText: 'Перетащите сюда файл Excel или нажмите',
        selectedFile: null,
      };
    },
    methods: {
        ...mapMutations([
            'setErrorMessage'
        ]),
        ...mapActions([
          'uploadEmployessInExcel_api'
        ]),
      closeModal() {
        this.showModal = false;
        this.resetDropzone();
      },
      triggerFileInput() {
        this.$refs.fileInput.click();
      },
      handleDrop(event) {
        this.isDragOver = false;
        const files = event.dataTransfer.files;
        if (files.length > 0) {
          this.selectedFile = files[0];
          this.dropzoneText = files[0].name;
        }
        event.preventDefault();
      },
      handleFileChange(event) {
        const files = event.target.files;
        if (files.length > 0) {
          this.selectedFile = files[0];
          this.dropzoneText = files[0].name;
        }
      },
      async uploadFile() {
        if (this.selectedFile) {
          let formData = new FormData();
          formData.append('excelFile', this.selectedFile);
          await this.uploadEmployessInExcel_api(formData).then((response) => {
            if (response.status && response.status == 200) {
              this.setErrorMessage("Файл успешно загружен");
              document.getElementById("UploadCloseBtn").click();
            }
          });
        } else {
            let message= {
                message: 'Сначала выберите файл.',
                type: 'danger'
            }
          this.setErrorMessage(message);
        }
      },
      resetDropzone() {
        this.dropzoneText = 'Перетащите сюда файл Excel или нажмите';
        this.selectedFile = null;
      },
    },
  };
  </script>
  
  <style scoped>
  .dropzone {
    display: flex;
    border: 2px dashed #FF5A49;
    border-radius: 7px;
    padding: 20px;
    text-align: center;
    align-items: center;
    color: #FF5A49;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: #e7e7e7;
    height: 300px;
  }
  
  .dropzone.dragover {
    background-color: #e9ecef;
  }
  
  </style>
  