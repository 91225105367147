const state = { 
    firstStep: true,
    secondStep: false,
    thirdStep: false,
    userInfo: ''
}
const getters = {
    getFirstStep(state) {
        return state.firstStep;
    },
    getSecondStep(state) {
        return state.secondStep;
    },
    getThirdStep(state) {
        return state.thirdStep;
    },
    getUserInfo(state) {
        return state.userInfo;
    }
}
const mutations = {
    setFirstStep(state, payload) {
        state.firstStep = payload;
    },
    setSecondStep(state, payload) {
        state.secondStep = payload;
    },
    setThirdStep(state, payload) {
        state.thirdStep = payload;
    },
    setUserInfo(state, payload) {
        state.userInfo = payload;
    }
}
const actions = {

}

export default {
    state,
    getters,
    mutations,
    actions
}