import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../../views/HomeView.vue'
import LandingPageView from '../../views/LandingPageView.vue'
import authService from '../../services/authService.js'
const routes = [
  {
    path: '/',
    name: 'landingPage',
    component: LandingPageView,
    meta: { requiresAuth: false },
  },
  {
    path: '/employess',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: false },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../../views/RegisterView.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/login',
    name: 'login',
    meta: { requiresAuth: false },
    component: () => import('../../views/LoginView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (authService.Authorization()) {
      next();
    } else {
      next('/');
    }
  } else {
    next();
  }
});

export default router
