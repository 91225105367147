
import apiService from "@/services/apiService";
const state = {
    historyYandexGo: ''
}

const getters = {
    getHistoryYandexGo(store) {
        return store.historyYandexGo;
    }
}

const mutations = {
    setHistoryYandexGo(contex, payload) {
        contex.historyYandexGo = payload;
    }
}   

const actions = {
    async getHistoryYandexGo_api(contex, params) {
        let options = {
            params: params,
            mutationFunc: 'setHistoryYandexGo',
            callFunction: apiService.Sync.historyYandexGo
        }
        return await contex.dispatch('requestHandler',options);
    },
    async startYandexGo_api(contex, inn) {
        let options = {
            params: inn,
            mutationFunc: 'setResponse',
            callFunction: apiService.Sync.startYandexGo
        }
        return await contex.dispatch('requestHandler',options);
    }

}


export default {
    state,
    getters,
    mutations,
    actions
}