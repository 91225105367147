<template>
<div class="modal fade" id="ProfileModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="ProfileModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="d-flex justify-content-between p-3">
        <h4 class="modal-title" id="ProfileModalLabel">Персональные данные</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="">
            <div class="d-flex flex-wrap">
                <div class="col-12 col-lg-6 px-3">
                    <h5 class="text-gray fw-bold mb-1">Название организации </h5>
                    <p class="ms-1">{{ Committent.name }}</p>
                </div>
                <div class="col-12 col-lg-6 px-3">
                    <h5 class="text-gray fw-bold mb-1">ИНН </h5>
                    <p class="ms-1 company-inn">{{ Committent.inn }} <i class="bi bi-copy" data-bs-toggle="tooltip" title="Копировать"></i></p>
                </div>
                <div class="col-12 col-lg-6 px-3">
                    <h5 class="text-gray fw-bold mb-1">Номер телефона </h5>
                    <p class="ms-1">{{ Committent.phone }}</p>
                </div>
                <div class="col-12 col-lg-6 px-3">
                    <h5 class="text-gray fw-bold mb-1">Электронная почта </h5>
                    <p class="ms-1">{{ Committent.email }}</p>
                </div>
                <div class="col-12 col-lg-6 px-3">
                    <h5 class="text-gray fw-bold mb-1">Cтрана </h5>
                    <p class="ms-1">{{ Committent.originCountry }}</p>
                </div>
                <div class="col-12 col-lg-6 px-3">
                    <h5 class="text-gray fw-bold mb-1">Адрес </h5>
                    <p class="ms-1">{{ Committent.address?.length > 0 ? Committent.address:'Нет данних' }}</p>
                </div>
            </div>
        </div>
      </div>
      <div class=" py-3 pt-0 px-4 d-flex justify-content-end align-items-end">
            <div>
            <button data-bs-toggle="modal" data-bs-target="#ProfileModal"
                class="default-btn secondary-btn ">Отмена</button>
            </div>
        </div>
    </div>
  </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    methods: {
        ...mapGetters([
            'getCommittentInfo'
        ])
    },
    computed: {
        Committent() {
            return this.getCommittentInfo();
        }
    }
}
</script>