<template>
    <header id="header" :class="{ 'headroom--unpinned': scrolled, 'headroom': isScrolled, 'isTopHeader': isTopHeader }">
        <div class="container-navbar">
            <div class="container">
                <div class="row">
                    <div class="nav-block d-flex justify-content-between align-items-center">
                        <div class="logo-navbar d-flex justify-content-between align-items-center">
                            <a href="/">
                                <img src="./../../assets/images/Faktura.sign.png" height="53px">
                            </a>
                        </div>
                        <div class="navbar-box d-flex justify-content-between align-items-center">
                            <ul class="box-menu">
                                <a href="#" style="text-decoration: none;">
                                    <li  class="nav-item ">Главная</li>
                                </a>
                                <a href="#Possibilities" style="text-decoration: none;">
                                    <li  class="nav-item ">Возможности</li>
                                </a>
                                <a href="#DocumentFlow" style="text-decoration: none;">
                                    <li  class="nav-item ">Документооборот</li>
                                </a>
                                <a href="#Feedback" style="text-decoration: none;">
                                    <li  class="nav-item ">Обратная связь</li>
                                </a>
                            </ul>
                            <!-- <a v-if="this.$route.name != 'register'"  
                                style="text-decoration: none;" class="btn-sign-in">Регистрация</a> -->
                            <RouterLink to="/employess" v-if="auth.Authorization()" style="text-decoration: none; cursor: pointer;" class="ms-3" >
                                <img  style="cursor: pointer" src="../../assets/images/account.png"  >
                            </RouterLink>
                            <a v-else style="text-decoration: none; cursor: pointer;" class="btn-sign-in" @click="getTickectFromFaktura()">Войти</a>
                        </div>
                    </div>
                    <transition name="slide-fade">
                        <div v-show="mobileMenu" v-cloak class="navbar-collapse">
                            <ul class="navbarMobile">
                                <li class="nav-item ">Menu</li>
                                <div class="Line-crm"></div>
                                <li class="nav-item ">Menu</li>
                                <div class="Line-crm"></div>
                                <li class="nav-item ">Menu</li>
                                <div class="Line-crm"></div>
                                <li class="nav-item ">Menu</li>
                                <div class="Line-crm"></div>
                            </ul>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { RouterLink } from 'vue-router';
import authService from '../../services/authService'
export default {
    data() {
        return {
            mobileMenu: false,
            isScrolled: false,
            scrolled: false,
            limitPosition: 500,
            lastPosition: 0,
            auth: authService

        }
    },
    methods: {
        redirectUrl(hashtag) {
            if (this.$route.name != 'home' || hashtag == 'home') {
                this.$router.push({ name: 'home' });
                if (hashtag != 'home') {
                    setTimeout(() => {
                        this.redirectUrl(hashtag);
                    }, 200);
                }
                else {
                    window.scrollTo(0, 0);

                }
            }
            else {

                this.mobileMenu = false;
                window.scrollTo(0, 0);
                let position = document.getElementById(hashtag).offsetTop;
                console.log(position);
                window.scrollTo({
                    top: position - 80,
                    behavior: 'smooth'
                })
            }
        },
        getTickectFromFaktura() {
            let qs = `response_type=code&client_id=AzamsTest&redirect_uri=` + window.location;
            window.location.href = `https://account.faktura.uz/Authorize?${qs}`;
        },
        updateScroll() {
            /* eslint-disable no-debugger */
            this.scrollPosition = window.scrollY

            if (this.scrollPosition > 10) {
                this.isScrolled = true;
            } else {
                this.isScrolled = false;
            }

            if (this.scrollPosition < 20) {
                this.isTopHeader = true;
            } else {
                this.isTopHeader = false;
            }
        },

        handleScroll() {
            if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
                this.scrolled = true;
                // move up!
            }

            if (this.lastPosition > window.scrollY) {
                this.scrolled = false;
                // move down
            }

            this.lastPosition = window.scrollY;
            // this.scrolled = window.scrollY > 250;
        },

    },
    mounted() {
        window.addEventListener('scroll', this.updateScroll);

    },
    created() {
        if (window.scrollY == 0) {
            this.isTopHeader = true;
        } else {
            this.isTopHeader = false;
        }
        window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    }
}
</script>