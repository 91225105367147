<template>
    <MainHeaderComponent>

    </MainHeaderComponent>
    <slot></slot>
    <MainFooterComponent>

    </MainFooterComponent>
</template>

<script>
import MainHeaderComponent from '../components/mainLayout/MainHeaderComponent.vue'
import MainFooterComponent from '../components/mainLayout/MainFooterComponent.vue'
export default {
    components: {
        MainHeaderComponent,
        MainFooterComponent
    }
}

</script>